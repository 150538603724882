<template>
  <div class="select-wrap" :class="name">
    <slot :triggleFn="showAreaSelect" :active="isShowPop">
      <DisplayItem @click.native="showAreaSelect" :active="isShowPop">
        <span class="xa-txt-14" :class="{ 'xa-txt-secondary': !value.name }">{{
          displayName
        }}</span>
      </DisplayItem>
    </slot>
    <TransitionFadeDown>
      <div class="select-pop" v-show="this.isShowPop">
        <AreaSelect
          :isShow="this.isShowPop"
          :selected="selectedValue"
          :selectLevel="selectLevel"
          @isShow="isShowPop = $event"
          @areaSelectEnd="onSubmit"
        />
      </div>
    </TransitionFadeDown>
  </div>
</template>
<script>
import AreaSelect from './AreaSelect.vue'
import DisplayItem from '@/components/xa-form/DisplayItem.vue'
import TransitionFadeDown from '@/components/xa-form/TransitionFadeDown.vue'
import { getParentNodeInTargetClass } from '../util'
export default {
  name: 'areaSingleSelect',
  components: {
    AreaSelect,
    DisplayItem,
    TransitionFadeDown
  },
  data() {
    return {
      name: 'areaSingleSelect' + parseInt(Math.random() * 1000),
      isShowPop: false,
      selectedValue: {}
    }
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    selectLevel: {
      type: [Number, String],
      default: -1
    }
  },
  computed: {
    displayName() {
      return this.value.name || this.placeholder
    }
  },
  methods: {
    onDocumentClick(e) {
      if (this.isShowPop === false) return
      var node = getParentNodeInTargetClass(e.target, this.name)
      if (node === null) this.isShowPop = false
    },
    onSubmit(value) {
      this.isShowPop = !this.isShowPop
      this.$emit('input', JSON.parse(JSON.stringify(value.val)))
    },
    showAreaSelect() {
      this.selectedValue = JSON.parse(JSON.stringify(this.value))
      this.isShowPop = !this.isShowPop
    }
  },
  mounted() {
    window.document.addEventListener('click', this.onDocumentClick)
  },
  beforeDestroy() {
    window.document.removeEventListener('click', this.onDocumentClick)
  }
}
</script>
<style scoped>
.select-wrap {
  position: relative;
}
.select-pop {
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  box-shadow: 0 2px 5px 4px #c0c4cc;
  border-radius: 5px;
  z-index: 999;
}
</style>
