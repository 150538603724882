<script>
import FormDropdown from '@/components/xa-form/coms/FormDropdown.vue'
class Cache {
  constructor() {
    this.database = {}
  }
  get(key, creater) {
    key += ''
    if (this.database[key] === undefined) {
      this.database[key] = creater()
    }
    return this.database[key]
  }
}
export default {
  render(h) {
    return h(FormDropdown, {
      props: {
        value: this.value,
        disabled: this.disabled,
        placeholder: this.placeholder,
        options: this.options
      },
      on: {
        input: val => {
          this.$emit('input', val)
        }
      }
    })
  },
  data() {
    return {
      options: [],
      cacheControler: null
    }
  },
  methods: {
    getAsyncOptions() {
      const vm = this
      const mCache = this.cacheControler()
      mCache
        .get(this.target, function() {
          return vm.$diyAction(vm.url, { [vm.urlKey]: vm.target })
        })
        .then(data => (this.options = this.handleOptions(data)))
    },
    handleOptions(result) {
      const options = []
      result.forEach(item => {
        if (item.id === undefined) {
          options.push({
            value: item.value,
            label: item.title
          })
        } else if (item.id !== undefined) {
          options.push({
            value: item.id + '',
            label: item.name
          })
        }
      })
      return options
    }
  },
  props: {
    target: null,
    placeholder: {
      type: String,
      default: '请选择'
    },
    value: null,
    disabled: {
      type: Boolean,
      default: false
    },
    url: String,
    urlKey: String
  },
  watch: {
    target() {
      window.console.log('target')
      this.getAsyncOptions()
      this.$emit('input', '')
    }
  },
  mounted() {
    window.console.log('mounted')
    const mCache = new Cache()
    this.cacheControler = function() {
      return mCache
    }
    this.getAsyncOptions()
  },
  beforeDestroy() {
    this.cacheControler = null
  }
}
</script>
<style lang="scss" scoped></style>
