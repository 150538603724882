<script>
import DisplayValue from '../DisplayValue'
function getTag({ mode, dataType }) {
  if (mode === 'view') return DisplayValue
  if (dataType === 'number') return 'el-input-number'
  return 'el-input'
}
export default {
  functional: true,
  render(h, context) {
    const tag = getTag(context.props)
    return h(tag, context.data, context.children)
  }
}
</script>
