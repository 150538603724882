<template>
  <TransitionGroupSlide :direction="direction" className="select-list" distance="80px">
    <div
      v-for="(item, index) in items"
      :key="index + item.name"
      :class="{ active: item.id == activeId }"
      @click.stop="onClick(item)"
      class="select-item xa-txt-regular"
    >
      <i
        v-if="selectable"
        class="select-icon iconfont"
        :class="item | selectedState(result)"
      ></i>
      <span>{{ item.name }}</span>
    </div>
  </TransitionGroupSlide>
</template>
<script>
import TransitionGroupSlide from '../TransitionGroupSlide.vue'
export default {
  components: {
    TransitionGroupSlide
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    activeId: [String, Number],
    selectable: Boolean,
    result: Array,
    direction: String
  },
  filters: {
    selectedState(item, lists) {
      return lists.indexOf(item) !== -1
        ? 'icon-xuanze xa-color-info'
        : 'icon-choose'
    }
  },
  methods: {
    onClick(item) {
      this.$emit('select', item)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.select-list {
  display: flex;
  flex-wrap: wrap;
}
.select-item {
  padding: 0 10px;
  min-width: 5em;
  list-style-type: none;
  line-height: 30px;
  cursor: pointer;
  font-size: 12px;
  &:hover,
  &.active {
    color: $color-blue;
  }
}
.select-icon {
  position: relative;
  top: 1px;
  margin-right: 2px;
}
</style>
