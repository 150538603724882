<template>
  <div @click="onClick" class="xa-cell" :class="{ 'xa-cell--focused': active }">
    <div class="xa-flex" :class="label.color">
      <slot>{{ label.txt }}</slot>
    </div>
    <slot name="right"></slot>
    <div
      v-if="needArrow"
      class="cell-icon"
      :class="{ 'cell-icon--active': active }"
    >
      <i class="el-icon-arrow-down"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: Object,
      default() {
        return {}
      }
    },
    color: String,
    needArrow: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.needArrow && this.$emit('dropdownclick')
    }
  }
}
</script>
<style lang="scss" scoped>
.xa-cell {
  padding-left: 8px;
  line-height: 38px;
  border-radius: 4px;
  border: 1px solid $border-color;
  &:hover {
    border-color: darken($border-color, 20%);
  }
  &:focus,
  &:active {
    border-color: $color-primary;
  }
  &--focused {
    border-color: $color-primary;
    outline: 0;
    box-shadow: 0 0 3px 0px $color-primary;
  }
}
.cell-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &--active {
    transform: rotate(180deg);
  }
}
</style>
