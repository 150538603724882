<template>
  <div class="file-view__box">
    <div
      class="xa-cell"
      v-for="(item, index) in items"
      :key="item.path + index"
      @click="onDownLoadFile(item.path)"
    >
      <span class="xa-flex">{{ item.name }}</span>
      <span>{{ item.size }}</span>
      <i v-if="item.path" class="el-icon-download"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default() {}
    }
  },
  methods: {
    onDownLoadFile(path) {
      if (!path) return
      try {
        window.open(path)
      } catch (error) {
        var elemIF = document.createElement('iframe')
        elemIF.src = path
        elemIF.style.display = 'none'
        document.body.appendChild(elemIF)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.file-view__box {
  padding: 0 8px;
  max-width: 720px;
  line-height: 40px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  div:hover {
    color: #20a0ff;
    cursor: pointer;
  }
  i {
    margin-left: 8px;
    font-size: 18px;
  }
}
</style>
