<script>
export default {
  name: 'FormDropdown',
  render(h) {
    return h(
      'el-select',
      {
        props: {
          value: this.value,
          disabled: this.disabled,
          placeholder: this.placeholder || '请选择'
        },
        on: {
          input: val => {
            this.$emit('input', val)
          }
        }
      },
      this.options.map((item, index) => {
        return h('el-option', {
          key: index + item.value,
          props: {
            label: item.label,
            value: item.value
          }
        })
      })
    )
  },
  props: {
    placeholder: {
      type: String,
      default: '请选择'
    },
    value: null,
    options: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
