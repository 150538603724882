<template>
  <div v-loading="loading" class="areaSelect-wrap" v-show="isShow">
    <AreaSelectTab
      :items="tabs"
      :activeName="curTabName"
      @change="onTabChange"
    />
    <div class="areaSelect-main">
      <AreaSeletctList
        :items="dataBase['id' + curDisplayListId]"
        :activeId="curDisplayListActive"
        :selectable="isInMutiSelect"
        :result="mutiSelectList"
        :direction="transitonDirection"
        @select="onItemSelect"
      />
    </div>
    <div class="xa-cell areaSelect-foot">
      <div class="xa-cell">
        <template v-if="needMutiSelect">
          <el-switch :value="isInMutiSelect" @input="toggleMutiSelect" />
          <span style="padding:0 8px 0 4px;">多选</span>
        </template>
        <template v-if="isInMutiSelect">
          <el-switch :value="isAllSelect" @input="toggleAllSelect" />
          <span style="padding:0 8px 0 4px;">全选</span>
        </template>
      </div>
      <div>
        <el-button class="" @click.native="toggleShow" size="small" type
          >取消</el-button
        >
        <el-button
          class=""
          @click.native="selectEnd"
          size="small"
          type="primary"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import AreaSelectTab from './AreaSelectTab.vue'
import AreaSeletctList from './AreaSeletctList.vue'
const defaultGetUpAreasSrc = '/common/area/up_areas'
const defaultGetAreasSrc = process.env.VUE_APP_API_AREAS_SELECT
export default {
  name: 'areaSelect',
  components: {
    AreaSelectTab,
    AreaSeletctList
  },
  props: {
    isShow: {
      type: Boolean
    },
    selected: {
      type: Object,
      default() {
        return { name: '', id: '' }
      }
    },
    getUpAreasSrc: {
      type: String,
      default: defaultGetUpAreasSrc
    },
    getAreasSrc: {
      type: String,
      default: defaultGetAreasSrc
    },
    selectLevel: {
      type: [Number, String],
      default: -1
    },
    needMutiSelect: {
      type: Boolean,
      default: false
    },
    filtersList: {
      // 过滤筛选区域
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      hasInit: false,
      loading: false,
      tabs: [],
      curTabName: '',
      curDisplayListId: '',
      curDisplayListActive: '',
      dataBase: {},
      currentLevel: -1, // 记录当前选择的是第几level
      currentId: 0, // 当前选择的最底层的id
      isInMutiSelect: false, // 是否处于多选状态
      mutiSelectList: [], // 多选时选中的项
      stopMouseleaveFn: false, // 是否暂停mouseleaveFn的事件
      stopMouseleaveTimeOut: 0,
      transitonDirection: ''
    }
  },
  watch: {
    isShow(val) {
      if (val) {
        this.init()
      }
    }
  },
  computed: {
    isAllSelect() {
      return (
        this.isInMutiSelect &&
        this.mutiSelectList.length ===
          this.dataBase['id' + this.curDisplayListId].length
      )
    }
  },
  methods: {
    onTabChange(name) {
      if (this.isInMutiSelect) this.toggleMutiSelect() // 如果此时是多选状态，要关闭
      if (this.curTabName === name) return
      let level = this.tabs.findIndex(item => item.name === name)
      level > -1 && this.tabChange(level)
    },
    tabChange(level) {
      const tab = this.tabs[level]
      this.transitonDirection = level > this.currentLevel ? 'right' : 'left'
      this.currentLevel = level
      this.curTabName = tab.name
      if (this.dataBase['id' + tab.upid]) {
        this.$nextTick(() => {
          this.curDisplayListId = tab.upid
          this.curDisplayListActive = tab.id
        })
      }
    },
    toggleAllSelect() {
      if (this.isAllSelect === false) {
        this.mutiSelectList = Array.from(
          this.dataBase['id' + this.curDisplayListId]
        )
      } else {
        this.mutiSelectList = []
      }
    },
    toggleMutiSelect() {
      this.isInMutiSelect = !this.isInMutiSelect
      if (this.isInMutiSelect === false) this.mutiSelectList = []
    },
    toggleShow() {
      this.$emit('isShow', false)
    },
    async fetchUpLevelData(id) {
      if (!this.getUpAreasSrc) return false
      const result = await this.$diyAction(this.getUpAreasSrc, { id })
      if (result.length === 0) return
      result.sort((r1, r2) => r1.level - r2.level)
      const dataBase = {}
      const tabs = result.map((item, index) => {
        const upid = index === 0 ? 0 : result[index - 1].id
        const key = 'id' + upid
        dataBase[key] = item.data
        return {
          upid,
          id: item.id,
          name: item.name,
          key,
          level: index
        }
      })
      this.tabs = tabs
      this.curTabName = result[result.length - 1].name
      this.curDisplayListId = tabs[result.length - 1].upid
      this.curDisplayListActive = id
      this.dataBase = dataBase
      this.currentLevel = tabs.length - 1
      this.currentId = id
    },
    async init() {
      this.loading = true
      if (this.selected && this.selected.id) {
        if (this.hasInit === false)
          // 如果没有初始化过的 要初始化
          await this.fetchUpLevelData(this.selected.id)
        this.hasInit = true
      } else {
        if (this.dataBase['id0'] === undefined) await this.fetchNextLevelData(0)
        this.tabs = [
          {
            upid: 0,
            id: '',
            name: '请选择',
            level: 0
          }
        ]
        this.curTabName = '请选择'
        this.curDisplayListId = '0'
        this.curDisplayListActive = ''
        this.currentLevel = 0
      }
      this.loading = false
    },
    selectEnd() {
      if (this.needMutiSelect && this.isInMutiSelect) {
        if (this.mutiSelectList.length > 0) {
          this.mutiSelectList.forEach(selectedItem => {
            this.$emit('areaSelectEnd', {
              action: 'areaSelect',
              val: {
                id: selectedItem.id,
                name: this.getPathName() + selectedItem.name
              }
            })
          })
        }
      } else {
        if (this.currentId > 0) {
          this.$emit('areaSelectEnd', {
            action: 'areaSelect',
            val: {
              id: this.currentId,
              name: this.getPathName()
            }
          })
        }
      }
      this.toClose()
    },
    handMutiSelect(item) {
      let index = this.mutiSelectList.indexOf(item)
      if (index === -1) {
        this.mutiSelectList.push(item)
      } else {
        this.mutiSelectList.splice(index, 1)
      }
    },
    async onItemSelect(item) {
      this.stopMouseleaveFn = true
      if (this.needMutiSelect && this.isInMutiSelect) {
        this.handMutiSelect(item)
        return
      }
      this.currentId = item.id
      let result = this.dataBase['id' + item.id]
      if (result === undefined) {
        result = await this.fetchNextLevelData(item.id)
      }
      this.updateTabs(item, result)
      if (result.length === 0) this.selectEnd()
    },
    updateTabs(newTab, result) {
      const curTab = this.tabs[this.currentLevel]
      this.tabs.splice(this.currentLevel, this.tabs.length - this.currentLevel)
      this.tabs.push({
        ...newTab,
        upid: curTab.upid,
        key: 'id' + newTab.id,
        level: this.currentLevel
      })
      if (result && result.length) {
        this.curTabName = '请选择'
        this.tabs.push({
          name: this.curTabName,
          upid: newTab.id,
          level: this.currentLevel + 1
        })
        this.currentLevel++
        this.curDisplayListId = newTab.id
        this.curDisplayListActive = ''
      } else {
        this.curTabName = newTab.name
        this.curDisplayListActive = this.currentId
      }
    },
    /**
     * 调用getAreasSrc接口，获取指定id下的子节点
     */
    async fetchNextLevelData(id) {
      this.loading = true
      let result
      if (
        this.selectLevel > -1 &&
        parseInt(this.selectLevel) === this.currentLevel
      ) {
        result = []
      } else {
        result = await this.$diyAction(this.getAreasSrc, { upid: id })
        result = this.filtersQueryData(result, this.currentLevel)
      }
      this.$set(this.dataBase, 'id' + id, result)
      this.loading = false
      return result
    },
    filtersQueryData(items, level) {
      if (
        this.filtersList &&
        this.filtersList[level] &&
        this.filtersList[level].length
      ) {
        const filtersCfgs = this.filtersList[level]
        items = items.filter(item => filtersCfgs.includes(item.id))
      }
      return items
    },
    getPathName() {
      const namesList = this.tabs
        .filter(item => item.id !== undefined)
        .map(item => item.name)
      return namesList.join('')
    },
    onResize() {
      if (this.isShow) this.toClose()
    },
    toClose() {
      this.mutiSelectList = []
      this.isInMutiSelect = false
      this.$emit('isShow', false)
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
<style scoped>
.areaSelect-wrap {
  width: 100%;
  left: 0;
  top: 0;
  height: initial;
  box-sizing: border-box;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  z-index: 9;
}

.areaSelect-main {
  max-height: 220px;
  padding-bottom: 15px;
}
.areaSelect-foot {
  height: 44px;
  padding: 0px 8px;
  justify-content: space-between;
  border-top: 1px solid #e4e7ed;
}
</style>
