import { evilFn, checkCurValueIsInTargetValue } from '@/utils/eventShow'
/**
 * 依赖显示功能
 * @param {*} config {immediateClear}
 * 简单情况 {control_id,value}
 * 复杂情况 {fn,items}
 * @param {*} variables
 * @param {*} variable
 */
export default function eventShow(config = {}, variables = this.variables) {
  try {
    let result = true
    /**
     * 依赖多个目标值的情况
     */
    let { items: showItems, fn: strFn } = config
    if (showItems.length) {
      let resultObj = {}
      showItems.forEach(item => {
        // eslint-disable-next-line camelcase
        const { control_id, value } = item
        const variablesValue = variables[item.control_id]
        resultObj = {
          key: control_id,
          result: checkCurValueIsInTargetValue(variablesValue, value)
        }
        strFn = strFn ? strFn.replace(resultObj.key, resultObj.result) : ''
      })
      if (showItems.length === 1) {
        result = resultObj.result
      } else {
        result = evilFn(strFn)
      }
    }
    return result
  } catch (e) {
    return true
  }
}
