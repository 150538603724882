<template>
  <el-date-picker
    ref="dateTime"
    :size="size"
    v-model="valueT"
    :type="model"
    @change="changeFn"
    :readonly="readonly"
    :picker-options="pickerOptions0"
    :placeholder="model | placeholderTip(placeholder)"
    :start-placeholder="$t('DatetimeStartPlaceholder')"
    :end-placeholder="$t('DatetimeEndPlaceholder')"
    :range-separator="rangeSeparator"
  ></el-date-picker>
</template>
<script>
function getPadNum(num) {
  return (num + '').padStart(2, 0)
}
function getDataFormate(mDate) {
  return `${mDate.getFullYear()}-${getPadNum(mDate.getMonth() + 1)}-${getPadNum(
    mDate.getDate()
  )} ${getPadNum(mDate.getHours())}:${getPadNum(
    mDate.getMinutes()
  )}:${getPadNum(mDate.getMilliseconds())}`
}
function getDate(mDate) {
  return `${mDate.getFullYear()}-${getPadNum(mDate.getMonth() + 1)}-${getPadNum(
    mDate.getDate()
  )} `
}
export default {
  name: 'datetime',
  data() {
    return {
      valueT: '',
      minTime: '',
      maxTime: '',
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      }
    }
  },
  props: {
    value: null,
    model: {
      type: String,
      default: 'datetime' // dates,year,month,date,daterange
    },
    maxDate: {
      type: String
    },
    minDate: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'mini'
    },
    rangeSeparator: {
      type: String,
      default: ' - '
    }
  },
  methods: {
    changeFn(avg) {
      window.console.log('changeFn', this.model, avg)
      if (avg === null) {
        this.$emit('input', '')
      } else if (this.model === 'dates' && Array.isArray(avg)) {
        const value = avg.map(getDate)
        return this.$emit('input', value)
      } else if (this.model === 'year' && avg instanceof Date) {
        return this.$emit('input', avg.getFullYear())
      } else if (this.model === 'month' && avg instanceof Date) {
        return this.$emit(
          'input',
          avg.getFullYear() + '-' + getPadNum(avg.getMonth() + 1)
        )
      } else if (this.model === 'date' && avg instanceof Date) {
        return this.$emit('input', getDate(avg))
      } else if (this.model === 'daterange' && avg.length === 2) {
        this.$emit('input', getDate(avg[0]) + ' - ' + getDate(avg[1]))
      } else {
        if (avg instanceof Date) {
          window.console.log(this.model, avg)
          this.$emit('input', getDataFormate(avg))
        } else if (avg.length === 2) {
          this.$emit('input', getDate(avg[0]) + ' - ' + getDate(avg[1]))
        }
      }
    },
    translateTime(timeStr) {
      var d = new Date(timeStr)
      if (d.toString().indexOf('Invalid') === -1) {
        return d
      }
      return false
    },
    disabledDate(time) {
      var Time = time.getTime()
      if (this.minTime) {
        if (Time > this.minTime - 8.64e7) {
          // console.info(time.toLocaleString(), Time, this.minTime);
          if (this.maxTime) {
            if (Time > this.maxTime) {
              // console.error(time.toLocaleString(), Time, this.maxTime);
              return true
            }
          }
          return false
        } else {
          return true
        }
      }
      if (this.maxTime) {
        if (Time > this.maxTime) {
          // console.error(time.toLocaleString(), Time, this.maxTime);
          return true
        }
      }
    },
    disabledDateFn(time) {
      var Time = time.getTime()
      // console.info(time.toLocaleString())
      if (this.minTime) {
        if (Time < this.minTime - 8.64e7) {
          return false
        }
      }
      if (this.maxTime) {
        if (Time > this.maxTime - 8.64e7) {
          return false
        }
      }
      return time.getTime() < Date.now() - 8.64e7
    }
  },
  filters: {
    placeholderTip(type, placeholder) {
      if (placeholder) {
        return placeholder
      }
      if (type === 'year') {
        return '请选择年'
      } else if (type === 'month') {
        return '请选择年月'
      } else if (type === 'date') {
        return '请选择日期'
      } else if (type === 'datetime') {
        return '请选择日期时间'
      } else if (type === 'datetimerange') {
        return '请选择日期时间范围'
      } else if (type === 'daterange') {
        return '请选择日期范围'
      }
    }
  },
  created() {
    this.pickerOptions0.disabledDate = this.disabledDate
  },
  mounted() {
    // console.info(this.value);
    if (this.value) {
      if (['monthrange', 'daterange', 'datetimerange'].includes(this.model)) {
        var times
        if (this.value.indexOf(' - ') > -1) {
          times = this.value.split(' - ')
        } else if (this.value.indexOf(' / ') > -1) {
          times = this.value.split(' / ')
        } else {
          times = this.value.split(this.rangeSeparator)
        }
        var timeList = []
        times.forEach(time => {
          var mtime = this.translateTime(time)
          if (mtime !== false) {
            timeList.push(mtime)
          }
        })
        if (timeList.length === 2) {
          this.valueT = timeList
        }
      } else {
        var time = this.translateTime(this.value)
        if (time !== false) {
          this.valueT = time
        }
      }
    }
    var keys = [
      {
        soure: 'minDate',
        targe: 'minTime'
      },
      {
        soure: 'maxDate',
        targe: 'maxTime'
      }
    ]
    keys.forEach(key => {
      if (this[key.soure]) {
        var mT = this.translateTime(this[key.soure])
        if (mT !== false) {
          this[key.targe] = mT.getTime()
        }
      }
    })
  }
}
</script>
