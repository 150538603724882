<template>
  <div :style="'--y:' + y">
    <transition name="fade">
      <slot></slot>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    y: {
      type: [String, Number],
      default: '-40px'
    }
  }
}
</script>
<style lang="scss" scoped>
.fade {
  &-enter-active {
    transition: all 0.3s ease-in-out;
  }
  &-enter {
    z-index: -1;
    opacity: 0;
    transform: translateY(var(--y));
  }
  &-enter-to {
    z-index: 10;
    opacity: 1;
    transform: translateY(-0px);
  }
  &-leave {
    opacity: 0.8;
    transform: translateY(-0px);
    &-active {
      transition: all 0.3s ease-in-out;
    }
    &-to {
      opacity: 0;
      transform: translateY(var(--y));
    }
  }
}
</style>
