<script>
export default {
  render(h) {
    const groupTag =
      this.type === 'checkbox' ? 'el-checkbox-group' : 'el-radio-group'
    const itemTag = this.type === 'checkbox' ? 'el-checkbox' : 'el-radio'
    return h(
      groupTag,
      {
        props: {
          value: this.value,
          disabled: this.disabled
        },
        on: {
          input: val => {
            this.$emit('input', val)
          }
        }
      },
      this.options.map(option => {
        return h(
          itemTag,
          {
            props: {
              label: typeof option === 'string' ? option : option.value
            }
          },
          typeof option === 'string' ? option : option.label
        )
      })
    )
  },
  props: {
    type: String,
    value: null,
    disabled: {
      type: Boolean,
      default: false
    },
    options: Array
  }
}
</script>
