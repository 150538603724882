<script>
export default {
  name: 'DisplayValue',
  props: ['value'],
  render(h) {
    return h('div', {
      style: 'padding: 8px 0;line-height: 24px;background-color: #f5f7fa;min-height:40px;',
      domProps: {
        innerHTML: this.value
      }
    })
  }
}
</script>
