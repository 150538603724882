export const ComsList = [
  'text',
  'textarea',
  'ueditor',
  'fileUpload',
  'fileView',
  'select',
  'area',
  'areaMulti',
  'dropdown',
  'cascadeDropdown',
  'checkgroup',
  'radio',
  'tabs',
  'upload',
  'datetime',
  'form-submit'
]
// 初始化组件的基本配置
const defaultArrayCom = [
  'fileUpload',
  'select',
  'checkgroup',
  'tabs',
  'upload',
  'areaMulti'
]
const defaultStringCom = [
  'ueditor',
  'text',
  'textarea',
  'dropdown',
  'cascadeDropdown',
  'radio',
  'datetime'
]
const aliasMap = {
  submit: 'form-submit',
  'images-upload': 'upload',
  areaSelect: 'area',
  areaSingleSelect: 'area',
  areaMultiSelect: 'areaMulti'
}
let formComDefaultValue = {
  area() {
    return {}
  }
}
defaultStringCom.forEach(com => {
  formComDefaultValue[com] = function() {
    return ''
  }
})
defaultArrayCom.forEach(com => {
  formComDefaultValue[com] = function() {
    return []
  }
})
let typeFnRule = {
  string(cfg, val, msg) {
    if (!val && val !== 0) {
      return {
        result: false,
        msg: msg || '请完成填写'
      }
    } else {
      return {
        result: true,
        msg: ''
      }
    }
  },
  array(cfg, val) {
    if (!val) {
      return {
        result: false
      }
    } else if (val.length === 0) {
      return {
        result: false,
        msg: '请完成选择'
      }
    } else if (val.length < cfg.min) {
      return {
        result: false,
        msg: `请最少选择${cfg.min}项`
      }
    } else {
      return {
        result: true,
        msg: ''
      }
    }
  },
  object(cfg, val, key, msg) {
    if (val && val[key]) {
      return {
        result: true,
        msg: ''
      }
    } else {
      return {
        result: false,
        msg: msg || '请选择'
      }
    }
  }
}
function formComVariableCheck(type) {
  // arguments.length === 2
  let typeRule = {
    text() {
      return typeFnRule['string'](...arguments)
    },
    textarea() {
      return typeFnRule['string'](...arguments)
    },
    ueditor() {
      return typeFnRule['string'](...arguments)
    },
    fileUpload() {
      return typeFnRule['array'](...arguments)
    },
    select() {
      return typeFnRule['array'](...arguments)
    },
    area() {
      return typeFnRule['object'](...arguments, 'id')
    },
    dropdown() {
      return typeFnRule['string'](...arguments)
    },
    cascadeDropdown() {
      return typeFnRule['string'](...arguments)
    },
    checkgroup() {
      return typeFnRule['array'](...arguments)
    },
    radio() {
      return typeFnRule['string'](...arguments)
    },
    tabs() {
      return typeFnRule['array'](...arguments)
    },
    upload() {
      return typeFnRule['array'](...arguments)
    },
    areaMulti() {
      return typeFnRule['array'](...arguments)
    },
    datetime() {
      return typeFnRule['string'](...arguments)
    }
  }
  return typeRule[type]
    ? typeRule[type]
    : window.console.error(`Can not find the type:${type} check function`)
}
/**
 * 需要把选项中value都变成字符串格式
 * @param {*} com
 */
function hackForOptions(com) {
  if (com && com.options) {
    com.options.map(op => {
      if (typeof op === 'object') {
        op.value += ''
      }
    })
  }
}
function isEmptyObject(obj) {
  var name
  for (name in obj) {
    return false
  }
  return true
}
export function initFormShowRule(item, key, immediateClearList, rules) {
  let { showRule, variable } = item
  if (showRule) {
    if (isEmptyObject(showRule)) {
      delete item.showRule
      return
    } else if (showRule.show) {
      item.showRule = showRule = showRule.show
    }
    if (!showRule.items || showRule.items.length === 0) {
      delete item.showRule
      return
    }
    if (showRule.immediateClear) {
      immediateClearList.push(Object.assign({ variable }, showRule))
    }
    rules[key] = {
      ...showRule,
      variable
    }
  }
}
function initDefaultValue(item, variables) {
  const { type, variable } = item
  let defaultValueFn = formComDefaultValue[type]
  if (defaultValueFn !== undefined && variable) {
    variables[variable] = variables[variable] || defaultValueFn(item)
  }
  if (type === 'select') {
    // 确保该select组件的初始值也是数组
    if (!Array.isArray(variables[variable])) {
      variables[variable] = [variables[variable]]
    }
  }
}
/**
 * 设置必填的校验信息
 * @param {*} item
 * @param {*} index
 * @param {*} vm
 */
function getRequireInfo(item, index, vm) {
  if (item.require || item.required) {
    return {
      config: {
        show: true,
        index,
        variable: item.variable,
        oldValue: undefined,
        check: formComVariableCheck(item.type).bind(vm, item)
      },
      result: {
        result: false,
        msg: ''
      }
    }
  }
}

export default function(items, values, vm) {
  let variables = Object.assign(values, {})
  let validateResult = {
    _: {
      result: true,
      msg: ''
    }
  }
  const immediateClearList = []
  const requireList = []
  const showRules = {}
  const showRulesResult = {}
  items.forEach((item, index) => {
    showRulesResult[index] = true
    const { type, variable } = item
    item.type = aliasMap[type] || type
    hackForOptions(item)
    initFormShowRule(item, index, immediateClearList, showRules)
    initDefaultValue(item, variables)
    const requireInfo = getRequireInfo(item, index, vm)
    if (requireInfo) {
      const { config, result } = requireInfo
      requireList.push(config)
      validateResult[variable] = result
    }
  })
  return {
    coms: Array.from(items), // 配置
    variables, // 结果集
    validateResult, // 校验结果
    immediateClearList, // 需要在eventShow为false后清除值的队列
    requireList, // 必填字段的队列
    showRulesResult: showRulesResult, // 显示结果
    showRules: Object.freeze(showRules) // 关联显示的规则
  }
}
