<template>
  <div class="select-wrapper" :class="name">
    <DisplayItem
      :label="label"
      @click.native="onClickSelecter"
      :active="isOpenPop"
    >
      <el-tag
        v-for="(tag, index) in value"
        :key="tag.title + index"
        closable
        disable-transitions
        @close="onRemove(index)"
      >
        {{ tag.title }}
      </el-tag>
      <div
        v-if="max > 0"
        slot="right"
        class="xa-txt-12"
        :class="{ 'xa-txt-red': upperLimit }"
      >
        {{ value.length }}/{{ max }}
      </div>
    </DisplayItem>
    <TransitionFadeDown>
      <div v-if="isOpenPop" class="select-pop__wrap">
        <AppLineLoading v-show="isLoading" />
        <div class="select-pop__search" v-if="config['search-able']">
          <SearchBar v-on:search-bar="searchBarChange" />
        </div>
        <div class="select-pop__main">
          <SelectOptions
            v-if="optionsList.length"
            :options="optionsList"
            :value="value"
            @select="onSelectItem"
          />
          <EmptyCom v-if="isEmpty" :config="config.blank_text" />
        </div>
      </div>
    </TransitionFadeDown>
  </div>
</template>
<script>
/* eslint-disable */
import DisplayItem from '@/components/xa-form/DisplayItem.vue'
import TransitionFadeDown from '@/components/xa-form/TransitionFadeDown.vue'
import SelectOptions from './Options'
export default {
  cache: {},
  components: {
    TransitionFadeDown,
    SelectOptions,
    SearchBar: () => import('@/components/AppSearchBar'),
    DisplayItem,
    EmptyCom: {
      render(h) {
        return h(
          'div',
          {
            class: 'empty-box'
          },
          [
            h('div', {}, [
              h('p', {}, [
                h('i', { class: 'el-icon-search' }),
                h('span', {}, this.config.title || '没有相关数据')
              ]),
              h('p', {
                domProps: {
                  innerHTML: this.config.subTitle || this.config.sub_title
                }
              })
            ])
          ]
        )
      },
      props: {
        config: {
          type: Object,
          default() {
            return {
              title: '没有相关内容',
              subTitle: '请输入关键字搜索'
            }
          }
        }
      }
    }
  },
  data() {
    return {
      name: 'complexDropdown' + parseInt(Math.random() * 1000),
      isOpenPop: false, // 下拉菜单是否打开
      optionsList: [], // 供选择的options项目
      isLoading: false
    }
  },
  props: {
    config: {
      type: Object,
      default() {
        return {
          src: {
            url: '/xaForm/select/options',
            params: ['id', 'name']
          },
          'search-able': true,
          blank_text: {
            icon: '',
            title: '',
            sub_title: ''
          }
        }
      }
    },
    variables: {
      type: Object,
      require: true
    },
    multiple: {
      type: Boolean,
      default: true // 是否是多选
    },
    max: {
      default: -1
    },
    value: Array,
    placeholder: String
  },
  computed: {
    /**
     * 判断是不是达到选择的最大项数
     */
    upperLimit() {
      if (this.max === -1 || this.max === undefined) return false
      return this.value.length >= this.max
    },
    label() {
      if (this.value.length == 0) {
        return {
          txt: this.placeholder || '请选择',
          color: 'xa-txt-placeholder'
        }
      }
    },
    isEmpty() {
      return this.optionsList.length === 0 && this.isLoading === false
    }
  },
  methods: {
    onRemove(index) {
      this.value.splice(index, 1)
    },
    getParentNode(currentNode, className) {
      while (
        currentNode &&
        currentNode.tagName !== 'HTML' &&
        currentNode.tagName !== 'BODY' &&
        currentNode.nodeType === 1
      ) {
        if (currentNode.className.indexOf(className) > -1) {
          return currentNode
        }
        currentNode = currentNode.parentNode
      }
      return null
    },
    // 当搜索内容发生改变
    searchBarChange(msg) {
      if (msg.action === 'search') {
        this.getSelectItemsAsy(msg.val)
      }
    },
    getSelectItemsAsy(keyword = '') {
      let reqQuery = {}
      let params = (this.config.src && this.config.src.params) || []
      for (let i = 0; i < params.length; i++) {
        reqQuery[params[i]] = this.variables[params[i]]
      }
      // 是dom事件
      reqQuery['keyword'] = keyword
      this.isLoading = true
      this.optionsList = []
      this.getCachePromise(this.config.src.url, reqQuery).then(data => {
        this.optionsList = data.options
        this.isLoading = false
      })
    },
    onClickSelecter() {
      if (this.isOpenPop) {
        this.isOpenPop = false
        return
      }
      this.isOpenPop = true
      this.getSelectItemsAsy()
    },
    getCachePromise(api, params) {
      let cacheKey = api + JSON.stringify(params)
      var cacheResult = this.$options.cache[cacheKey]
      return new Promise((resolve, reject) => {
        if (cacheResult !== undefined) {
          resolve(cacheResult)
        } else {
          this.$diyAction(api, params).then(data => {
            this.$options.cache[cacheKey] = data
            resolve(data)
          })
        }
      })
    },
    /**
     * 选择某item
     * 单选：直接替换结果
     * 多选：已选的，删除;未选的，加入结果集合。
     */
    onSelectItem(item) {
      if (this.multiple === false || this.multiple === undefined) {
        this.value.pop()
        this.value.push(item)
        this.isOpenPop = false
        return
      }
      let index = this.value.findIndex(mTtem => mTtem.value === item.value)
      if (index !== -1) {
        this.value.splice(index, 1)
      } else {
        if (this.upperLimit) return // 达到选择的上限
        this.value.push(item)
      }
    },
    onDocumentClick(e) {
      if (this.isOpenPop === false) return
      var node = this.getParentNode(e.target, this.name)
      if (node === null) this.isOpenPop = false
    }
  },
  mounted() {
    window.document.addEventListener('click', this.onDocumentClick)
  },
  beforeDestroy() {
    window.document.removeEventListener('click', this.onDocumentClick)
  }
}
</script>
<style scoped lang="scss">
.select-wrapper {
  position: relative;
}
.select-pop__wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  padding: 4px;
  background: #fff;
  box-shadow: 0 2px 5px 4px #c0c4cc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999;
  .select-pop__main {
    max-height: 200px;
    overflow-y: auto;
  }
}
.select-pop__search {
  padding: 4px;
}
/deep/.el-tag {
  margin-right: 4px;
}
/deep/ .empty-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 120px;
  line-height: 2;
  color: $color-text-placeholder;
}
</style>
