<template>
  <div class="xa-form">
    <div
      v-for="(com, index) in coms"
      :key="index + com.label"
      :class="[
        com.variable &&
        validateResult[com.variable] &&
        validateResult[com.variable].result
          ? 'check-success'
          : 'check-fail',
        '_form_com_' + index
      ]"
      v-show="showRulesResult[index]"
    >
      <FormLayout
        :icon="!!com.require || !!com.required"
        v-if="com.type"
        :label="com.type === 'form-submit' ? '' : com.label"
      >
        <template v-if="com.type === 'text' || com.type === 'textarea'">
          <FormText
            v-bind="com"
            :type="com.type"
            :mode="com.mode"
            v-model="variables[com.variable]"
            :show-word-limit="com.maxlength > 0"
          />
        </template>
        <template v-else-if="com.type === 'ueditor'">
          <Ueditor v-model="variables[com.variable]" />
        </template>
        <template v-else-if="com.type === 'fileUpload'">
          <FileUpload v-bind="com" v-model="variables[com.variable]" />
        </template>
        <template v-else-if="com.type === 'fileView'">
          <FileViewer :items="com.items" />
        </template>
        <template v-else-if="com.type === 'select'">
          <FormSelect
            :mode="com.mode"
            :variables="variables"
            :variable="com.variable"
            :config="com"
            :max="com.max"
            :placeholder="com.placeholder"
            :multiple="com.multiple"
            v-model="variables[com.variable]"
          />
        </template>
        <template v-else-if="com.type === 'area'">
          <FormAreaSelect
            :mode="com.mode"
            :placeholder="com.placeholder"
            :selectLevel="com.sl"
            v-model="variables[com.variable]"
          />
        </template>
        <template v-else-if="com.type === 'areaMulti'">
          <FormAreaMultiSelect
            :mode="com.mode"
            :placeholder="com.placeholder"
            :selectLevel="com.sl"
            :max="com.max"
            :filtersList="com.filtersList"
            v-model="variables[com.variable]"
          />
        </template>
        <template v-else-if="com.type === 'dropdown'">
          <FormDropdown
            :options="com.options"
            :disabled="com.mode === 'view'"
            :placeholder="com.placeholder"
            v-model="variables[com.variable]"
          />
        </template>
        <template v-else-if="com.type === 'cascadeDropdown'">
          <FormCascadeDropdown
            :target="variables[com.target]"
            :url="com.url"
            :urlKey="com.urlKey"
            :disabled="com.mode === 'view'"
            :placeholder="com.placeholder"
            v-model="variables[com.variable]"
          />
        </template>
        <template v-else-if="com.type === 'checkgroup' || com.type === 'radio'">
          <FormSelectGroup
            :type="com.type === 'checkgroup' ? 'checkbox' : 'radio'"
            :options="com.options"
            :disabled="com.mode === 'view'"
            v-model="variables[com.variable]"
          />
        </template>
        <template v-else-if="com.type === 'tabs'">
          <FormTags v-bind="com" v-model="variables[com.variable]" />
        </template>
        <template v-else-if="com.type === 'upload'">
          <FormUpload
            :actionUrl="com.actionUrl"
            :resourceUrl="com.resource_url"
            :mode="com.mode"
            :max="com.max"
            :size="com.size"
            v-model="variables[com.variable]"
            :placeholder="com.placeholder"
          />
        </template>
        <template v-else-if="com.type === 'datetime'">
          <FormDateTime
            :mode="com.mode"
            :model="com.model"
            :maxDate="com.maxDate"
            :minDate="com.minDate"
            v-model="variables[com.variable]"
            :placeholder="com.placeholder"
            size="large"
          />
        </template>
        <template v-else-if="com.type === 'form-submit'">
          <el-button type="primary" @click="onSubmitClick(com)" size="small">{{
            com.label
          }}</el-button>
        </template>
        <div
          class="xa-txt-12 xa-txt-secondary"
          slot="tips"
          v-html="com.tips"
        ></div>
      </FormLayout>
      <FormLayout
        :icon="false"
        v-if="validateResult[com.variable] && validateResult[com.variable].msg"
      >
        <el-alert
          :title="validateResult[com.variable].msg"
          type="error"
          show-icon
          @close="validateResult[com.variable].msg = ''"
        />
      </FormLayout>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import FileViewer from '@/components/xa-form/coms/FileViewer.vue'
import FormText from '@/components/xa-form/coms/FormText.vue'
import FormSelect from '@/components/xa-form/coms/FormSelect.vue'
import FormAreaSelect from '@/components/xa-form/coms/FormAreaSelect.vue'
import FormAreaMultiSelect from '@/components/xa-form/coms/FormAreaMultiSelect.vue'
import FormDropdown from '@/components/xa-form/coms/FormDropdown.vue'
import FormCascadeDropdown from '@/components/xa-form/coms/FormCascadeDropdown.vue'
import FormSelectGroup from '@/components/xa-form/coms/FormSelectGroup.vue'
import FormTags from '@/components/xa-form/coms/FormTags.vue'
import FormUpload from '@/components/xa-form/coms/FormUpload.vue'
import FormDateTime from '@/components/xa-form/coms/FormDateTime.vue'

import FormLayout from '@/components/xa-form/FormLayout.vue'

import eventShow from '@/components/xa-form/eventShow'
import initFormItems from '@/components/xa-form/initItems'

export default {
  components: {
    FormAreaMultiSelect,
    FormLayout,
    FormAreaSelect,
    FileViewer,
    FormSelect,
    FormText,
    FormCascadeDropdown,
    FormDateTime,
    FormDropdown,
    FormSelectGroup,
    FormUpload,
    FormTags,
    FileUpload: () => import('@/components/xa-form/fileUpload/Index.vue'),
    Ueditor: () => import('@/components/ueditor')
  },
  data() {
    return {
      isTest: true,
      requireList: [],
      coms: [],
      variables: {},
      immediateClearList: [],
      validateResult: null,
      checkVariablesTimeout: null,
      isSending: false,
      showRules: null, // 级联显示的配置
      showRulesResult: null // 级联显示的结果
    }
  },
  watch: {
    variables: {
      deep: true,
      handler(newVal) {
        window.console.log('watch', newVal)
        clearTimeout(this.checkVariablesTimeout)
        this.checkVariablesTimeout = setTimeout(() => {
          this.checkShowRules()
          this.checkRequestVariable(false)
          this.$emit('updateValue', newVal)
        }, 350)
      }
    }
  },
  props: {
    items: Array, // 表单配置
    values: Object // 表单初始值
  },
  methods: {
    eventShow,
    // 检查级联显示状况
    checkShowRules() {
      const showRulesResult = {}
      const showVariable = new Set()
      Object.keys(this.showRules).forEach(key => {
        const rule = this.showRules[key]
        const show = (showRulesResult[key] = this.eventShow(rule))
        if (rule.variable) {
          // 对于些展示控件，是没有variable的
          show && showVariable.add(rule.variable)
        }
      })
      this.showRulesResult = Object.assign(
        this.showRulesResult,
        showRulesResult
      )
      this.showVariable = showVariable
    },
    focusToCom(index) {
      let el = this.$el.querySelector('._form_com_' + index)
      el && el.scrollIntoView()
    },
    /**
     * 检查必填字段
     * @param {Boolean} needTip 指示要不要有文字提示
     */
    checkRequestVariable(needTip) {
      clearTimeout(this.checkVariablesTimeout)
      this.requireList.forEach(item => {
        const { oldValue, variable } = item
        const newValue = this.variables[variable]
        // 如果没有改变值的，并且不是数组，不需要提示，
        if (oldValue === newValue && !Array.isArray(oldValue) && !needTip)
          return
        const { result, msg } = item.check(this.variables[variable])
        const msgObj = {
          result,
          msg
        }
        if (needTip === false && msg) {
          msgObj.msg = ''
        }
        Object.assign(this.validateResult[variable], msgObj)
        item.oldValue = this.variables[variable]
      })
    },
    // 检查要提交的数据，找出不符合要求的项，已经跳过隐藏的项
    checkVariables(variables = this.variables) {
      this.checkRequestVariable(true)
      let canSubmit = true
      let warnIndex = -1
      this.requireList.forEach(item => {
        if (this.showVariable.has(item.variable)) {
          let { result } = this.validateResult[item.variable]
          if (canSubmit && result === false) {
            canSubmit = false
            warnIndex = item.warnIndex
          }
        }
      })
      return { canSubmit, warnIndex }
    },
    // 获取提交的数据
    getSubmitVariables() {
      let result = this.checkSubmitVariables()
      if (result) {
        return this.variables
      }
    },
    checkSubmitVariables() {
      let { canSubmit, warnIndex } = this.checkVariables(this.variables)
      if (!canSubmit) {
        this.focusToCom(warnIndex)
      }
      return canSubmit
    },
    async onSubmitClick(submitBtnCfg) {
      if (this.isSending) return
      this.isSending = true
      let result
      let submitValue = this.getSubmitVariables()
      if (submitValue) {
        result = await this.toSubmitValue(submitBtnCfg, submitValue)
        if (result === undefined) {
          this.isSending = false
          return
        }
      }
      this.isSending = false
      submitValue &&
        this.$emit('finish', {
          cfg: submitBtnCfg,
          value: submitValue,
          result
        })
      return result
    },
    /**
     * 弹窗提示
     */
    askMsg(message) {
      const confirmButtonText = this.$t('Confirm')
      const cancelButtonText = this.$t('Cancel')
      const doingText = this.$t('执行中...')
      const successText = this.$t('SuccessOperation')
      return new Promise((resolve, reject) => {
        this.$msgbox({
          title: '',
          message: message,
          showCancelButton: true,
          confirmButtonText,
          cancelButtonText,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = doingText
              resolve(result => {
                done()
                if (result) {
                  this.$message({
                    type: 'success',
                    showClose: true,
                    message: successText
                  })
                }
              })
            } else {
              done()
            }
          },
          callback: (action, instance) => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = confirmButtonText
          }
        })
      })
    },
    async toSubmitValue(submitBtnCfg, variables) {
      let vm = this
      let confirmContent =
        submitBtnCfg.confirm_content || submitBtnCfg.confirm || '确定要提交？'
      const done = await this.askMsg(confirmContent)
      try {
        let result = await this.$diyAction(submitBtnCfg.src, variables, 'post')
        done(true)
        return result || {}
      } catch (error) {
        window.console.log('error', error)
        done()
        setTimeout(() => {
          vm.$confirm(error.msg || error.message || error, '提示', {
            showCancelButton: false,
            confirmButtonText: '确定',
            type: 'error'
          })
        }, 200)
      }
    },
    clearVariablesValue(variable) {
      if (this.variables[variable] instanceof Array) {
        this.variables[variable] = []
      } else if (this.variables[variable] instanceof Object) {
        this.variables[variable] = {}
      } else {
        if (
          this.variables[variable] === false ||
          this.variables[variable] === true
        ) {
          return
        }
        this.variables[variable] = ''
      }
    },
    // 专门处理被依赖对象的值需要被清空的清空
    initImmediateClearList(immediateClearList) {
      let target = {}
      immediateClearList.forEach(item => {
        if (item.control_id) {
          let mTarget =
            target[item.control_id] || (target[item.control_id] = [])
          item.variable && mTarget.push(item.variable)
        } else {
          item.items.forEach(mItem => {
            let mTarget =
              target[mItem.control_id] || (target[mItem.control_id] = [])
            item.variable && mTarget.push(item.variable)
          })
        }
      })
      let keys = Object.keys(target)
      keys.forEach(key => {
        this.$watch('variables.' + key, () => {
          this.$nextTick(function() {
            target[key].forEach(variable => this.clearVariablesValue(variable))
          })
        })
      })
    }
  },
  mounted() {
    let {
      coms,
      variables,
      validateResult,
      immediateClearList,
      requireList,
      showRulesResult,
      showRules
    } = initFormItems(
      this.items,
      JSON.parse(JSON.stringify(this.values || {})),
      this
    )
    this.coms = coms
    this.showRules = showRules
    this.showRulesResult = showRulesResult
    this.variables = variables
    this.validateResult = validateResult
    immediateClearList.length && this.initImmediateClearList(immediateClearList)
    this.immediateClearList = immediateClearList
    this.requireList = requireList
  }
}
</script>
<style lang="scss">
.check-success .icon-xingxing {
  color: $color-blue;
}
.check-fail .icon-xingxing {
  color: $color-red;
}
</style>
