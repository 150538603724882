<script>
import Edit from '../formAreaSelect/AreaMultiSelect.vue'
import View from '../DisplayTabView.vue'
export default {
  functional: true,
  render(h, context) {
    const { mode } = context.props
    if (mode === 'view') context.data.attrs.label = 'name'
    return h(mode === 'view' ? View : Edit, context.data, context.children)
  }
}
</script>
