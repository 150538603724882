<script>
import Edit from '../Datetime.vue'
import View from '../DisplayValue.vue'
export default {
  functional: true,
  render(h, context) {
    const { mode } = context.props
    return h(mode === 'view' ? View : Edit, context.data, context.children)
  }
}
</script>
