<template>
  <div class="tag-box">
    <el-tag v-for="(item, index) in value" :key="index">{{
      label ? item[label] : item
    }}</el-tag>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
    label: {
      type: String,
      default: 'name'
    }
  }
}
</script>
<style lang="scss" scoped>
.tag-box {
  min-height: 40px;
  background-color: rgb(245, 247, 250);
  .el-tag {
    margin: 4px;
  }
}
</style>
