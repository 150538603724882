<script>
import AreaSingleSelect from '../formAreaSelect/AreaSingleSelect.vue'
const DisplayHtml = {
  name: 'DisplayHtml',
  props: ['value'],
  render(h) {
    return h('div', {
      style: 'padding: 8px 0;line-height: 24px;background-color: #f5f7fa;',
      domProps: {
        innerHTML: this.value ? this.value.name : '-'
      }
    })
  }
}
function getTag({ mode }) {
  if (mode === 'view') return DisplayHtml
  return AreaSingleSelect
}
export default {
  functional: true,
  render(h, context) {
    const tag = getTag(context.props)
    return h(tag, context.data, context.children)
  }
}
</script>
