<template>
  <div class="form-layout">
    <div class="form-layout__label" v-if="label">
      <span v-if="icon" class="icon-xingxing">*</span>
      <label>{{ label }}</label>
    </div>
    <div class="form-layout__content">
      <slot></slot>
    </div>
    <div class="form-layout__tips xa-txt-12 xa-txt-secondary">
      <slot name="tips"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'xaformLayout',
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style scoped lang="scss">
.form-layout {
  margin: 16px 0;
  padding: 0px 16px;
  &__label {
    position: relative;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
  }
  &__content {
    flex: 1;
    max-width: 700px;
  }
  &__tips {
    margin-top: 4px;
    line-height: 2;
  }
  .icon-xingxing {
    position: absolute;
    top: 4px;
    left: -10px;
    width: 1em;
    font-size: 18px;
    font-weight: bold;
    &::before {
      content: '';
      display: none;
    }
  }
}
</style>
