<template>
  <div class="options__wrap">
    <div
      class="options__item xa-txt-14"
      v-for="(item, index) in options"
      :key="index"
      :class="item | checkIsSelected(value)"
      @click="$emit('select', item)"
    >
      <div class="xa-cell">
        <span class="xa-flex">{{ item.title }}</span>
        <span class="xa-cell__ft options__icon">
          <i class="el-icon-check"></i>
        </span>
      </div>
      <DisplayTips
        v-if="item.items && item.items.length"
        :items="item.items"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    DisplayTips: {
      render(h) {
        return h(
          'div',
          {
            class: 'xa-cell xa-flex-wrap'
          },
          this.items.map((item, index) => {
            return h(
              'p',
              {
                className: 'item',
                key: index,
                style: 'font-size:12px;line-height:20px;margin-right:8px;'
              },
              item.key + (item.key && item.value && ':') + item.value
            )
          })
        )
      },
      props: {
        items: {
          type: Array,
          default() {
            return []
          }
        }
      }
    }
  },
  props: {
    options: {
      type: Array
    },
    value: {
      type: Array
    }
  },
  filters: {
    checkIsSelected(item, value) {
      return value.find(mItem => JSON.stringify(mItem) === JSON.stringify(item))
        ? 'options__item--check'
        : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.options__wrap {
  padding: 0 4px;
}

.options__item {
  padding: 4px;
  line-height: 32px;
  cursor: pointer;
  &--check {
    color: $color-primary;
    .options__icon {
      opacity: 1;
      font-size: 16px;
    }
  }
}
.options__icon {
  opacity: 0;
}
.options__item:hover {
  background: #f5f7fa;
}
</style>
