<template>
  <el-tabs
    type="card"
    class="area-seletct-tab"
    :value="activeName"
    @input="$emit('change', arguments[0])"
  >
    <el-tab-pane
      v-for="item in items"
      :key="item.name"
      :label="item.name"
      :name="item.name"
    />
  </el-tabs>
</template>
<script>
export default {
  props: {
    activeName: String,
    items: {
      type: Array,
      default() {
        return [
          { name: '北京市' },
          { name: '东城区' },
          { name: '华四街道' },
          { name: '请选择' }
        ]
      }
    }
  }
}
</script>
<style>
.area-seletct-tab .el-tabs__item {
  padding: 0 8px !important;
}
</style>
