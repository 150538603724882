<template>
  <DisplayItem @click.native="clickFn" :needArrow="false">
    <div class="tags-box">
      <el-tag
        v-for="(tag, index) of value"
        :key="tag"
        closable
        :class="{ twinkle: twinkle == index }"
        @close="onRemove(tag)"
      >
        {{ tag }}
      </el-tag>
      <el-input
        v-show="addable && !upperLimit"
        :placeholder="placeholder"
        v-model="curInput"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
    </div>
    <div
      v-if="max > 0"
      slot="right"
      class="xa-txt-12 tags-num"
      :class="{ 'xa-txt-red': upperLimit }"
    >
      {{ value.length }}/{{ max }}
    </div>
  </DisplayItem>
</template>
<script>
import DisplayItem from './DisplayItem'
export default {
  name: 'xaformTabs',
  components: {
    DisplayItem
  },
  data() {
    return {
      curInput: '',
      twinkle: -1
    }
  },
  props: {
    value: Array,
    // label: String,
    placeholder: {
      type: String,
      default: '请输入、回车代表一次输入'
    },
    max: Number,
    mode: String,
    addable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    upperLimit() {
      if (this.max !== undefined && this.max > 0) {
        return this.value.length >= this.max
      }
      return false
    }
  },
  methods: {
    showInput() {
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    clickFn() {
      this.showInput()
    },
    onRemove(target) {
      if (this.value) {
        this.value.splice(this.value.indexOf(target), 1)
      }
    },
    addtab(value) {
      this.twinkle = -1
      this.twinkle = this.value.indexOf(value)
      if (this.twinkle === -1) {
        this.value.push(value)
      }
    },
    handleInputConfirm() {
      let curInput = this.curInput.trim()
      this.twinkle = -1
      this.twinkle = this.value.indexOf(curInput)
      if (curInput && this.twinkle === -1) {
        this.value.push(curInput)
      }
      this.curInput = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.tags-box {
  margin-left: -8px;
  padding-right: 2px;
  display: flex;
  flex-wrap: wrap;
  /deep/.el-tag {
    margin: 3px 4px;
  }
  /deep/.el-input {
    flex-grow: 1;
    margin-left: 4px;
    width: 10em;
  }
  /deep/ .el-input__inner {
    padding: 0 8px;
    outline: none;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    &:active,
    &:focus {
      border-color: $color-primary;
    }
  }
}
.tags-num {
  padding-right: 4px;
}
.twinkle {
  animation: change 1s ease-in 3;
  -webkit-animation: change 1s ease-in 3;
}

@keyframes change {
  0% {
    text-shadow: 0 0 4px #000;
  }

  0%,
  50% {
    background: #3ce0c1;
  }

  50% {
    text-shadow: 0 0 40px #000;
  }
}
</style>
