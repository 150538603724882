/**
 * 从当前元素，向上查找元素，其类包含指定类
 * @param {*} currentNode 查找起点
 * @param {*} include 指定要包含的类
 * @param {*} exclude 截至于指定的类
 */
export function getParentNodeInTargetClass(currentNode, include, exclude) {
  while (
    currentNode &&
    currentNode.tagName !== 'HTML' &&
    currentNode.tagName !== 'BODY' &&
    currentNode.nodeType === 1
  ) {
    if (include && currentNode.classList.contains(include)) return currentNode
    if (exclude && currentNode.classList.contains(exclude)) return null
    currentNode = currentNode.parentNode
  }
  return null
}
