<template>
  <div class="select-wrap" :class="name">
    <DisplayItem @click.native.stop="showAreaSelect" :active="this.isShowPop">
      <template v-if="value.length">
        <el-tag
          v-for="(tag, index) in value"
          :key="tag.name + index"
          closable
          disable-transitions
          @close="onRemove(index)"
        >
          {{ tag.name }}
        </el-tag>
      </template>
      <template v-else>
        <span class="xa-txt-placeholder xa-txt-14">{{ placeholder }}</span>
      </template>
      <div
        v-if="max > 0"
        slot="right"
        class="xa-txt-12"
        :class="{ 'xa-txt-red': upperLimit }"
      >
        {{ value.length }}/{{ max }}
      </div>
    </DisplayItem>
    <TransitionFadeDown>
      <div class="select-pop" v-show="this.isShowPop">
        <AreaSelect
          :isShow="this.isShowPop"
          :needMutiSelect="true"
          :selectLevel="selectLevel"
          :filtersList="filtersList"
          @isShow="isShowPop = $event"
          @areaSelectEnd="xaform_event"
        />
      </div>
    </TransitionFadeDown>
  </div>
</template>
<script>
import AreaSelect from './AreaSelect.vue'
import DisplayItem from '@/components/xa-form/DisplayItem.vue'
import TransitionFadeDown from '@/components/xa-form/TransitionFadeDown.vue'
import { getParentNodeInTargetClass } from '../util'
export default {
  name: 'areaMultiSelect',
  components: {
    AreaSelect,
    DisplayItem,
    TransitionFadeDown
  },
  data() {
    return {
      name: 'areaMultiSelect' + parseInt(Math.random() * 1000),
      isShowPop: false
    }
  },
  props: {
    value: {
      type: Array,
      default() {
        return [
          {
            id: 7559,
            name: '山西省长治市平顺县北耽车乡'
          },
          {
            id: 1,
            name: '北京市'
          }
        ]
      }
    },
    // 限制选择数量
    max: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    selectLevel: {
      type: [Number, String],
      default: -1
    },
    // 过滤筛选区域
    filtersList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    upperLimit() {
      return this.max > 0 && this.value.length >= this.max
    }
  },
  methods: {
    onRemove(index) {
      this.value.splice(index, 1)
    },
    xaform_event({ action, val }) {
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i].id === val.id) {
          return
        }
      }
      if (this.upperLimit) return
      if (action === 'areaSelect') {
        this.value.push(val)
      }
    },
    showAreaSelect() {
      if (this.upperLimit) return
      this.isShowPop = !this.isShowPop
    },
    onDocumentClick(e) {
      if (this.isShowPop === false) return
      var node = getParentNodeInTargetClass(e.target, this.name)
      if (node === null) this.isShowPop = false
    }
  },
  mounted() {
    window.document.addEventListener('click', this.onDocumentClick)
  },
  beforeDestroy() {
    window.document.removeEventListener('click', this.onDocumentClick)
  }
}
</script>
<style scoped lang="scss">
.select-wrap {
  position: relative;
}
.select-pop {
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  box-shadow: 0 2px 5px 4px #c0c4cc;
  border-radius: 5px;
  z-index: 999;
}
.placeholder-tip {
  margin-left: 12px;
  color: #888;
}
.add-btn {
  margin-left: 5px;
  color: rgb(32, 160, 255);
  line-height: 28px;
  height: 28px;
  overflow: hidden;
}
/deep/.el-tag {
  margin-right: 4px;
}
</style>
